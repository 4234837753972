import * as Dialog from '@radix-ui/react-dialog';


interface AlertDialogProps {
  open: boolean;
  setOpen: (value: boolean) => void;
  message: string;
  tittle?: string;
  action?: () => void;
}

const AlertDialog = (props: AlertDialogProps) => {

  return (
    <Dialog.Root open={props.open} onOpenChange={props.setOpen}>
      <Dialog.Portal>
        <Dialog.Overlay className="fixed inset-0 bg-overlay md:bg-overlayMd bg-opacity-50 md:bg-opacity-40 z-[60]" />
        <Dialog.Content className="fixed bottom-0 md:bottom-auto md:top-1/2 md:left-1/2 md:transform md:-translate-x-1/2 md:-translate-y-1/2 bg-dialogBg px-20 py-12 rounded-t-lg md:rounded-lg w-full md:w-fit md:max-w-1/2 md:min-w-[400px] mx-auto z-[65] outline-none focus:ring-transparent">
          {props.tittle && props.tittle.length > 0 ? (
            <Dialog.Title className="mb-4 text-xl font-bold text-center text-black font-poppins">{props.tittle}</Dialog.Title>
          ) : (
            <Dialog.Title />
          )}
          <Dialog.Description className="text-base text-center text-black font-dmsans">{props.message}</Dialog.Description>
          <div className="flex mt-[18px] justify-center space-x-2">
            <button
              className="px-8 py-2 text-base text-white bg-primary font-dmsans"
              onClick={() => props.action ? props.action() : props.setOpen(false)}
            >
              Ok
            </button>
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  )
}

export default AlertDialog;
