import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import Image from 'next/image';
import { useSession, signIn } from "next-auth/react";
import axios from 'axios';
import { useUserStore } from '@/stores/useUserStore';
import { User } from '@/stores/initialState';
import Link from 'next/link';
import AlertDialog from '../Utils/AlertDialog';


const Login = () => {
  const router = useRouter();
  const { sbind, redirect } = router.query;
  const { userInfo, getUser, updateUser } = useUserStore();
  const { data, status } = useSession();
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [userData, setUserData] = useState(getUser());
  const [message, setMessage] = useState("");
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setUserData(getUser());
  }, [getUser, userInfo])

  useEffect(() => {
    const checkInput = async () => {
      if (email === "" || email.length === 0) {
        setMessage("Please enter your email");
      } else if (password === "" || password.length === 0) {
        setMessage("Please enter your password");
      } else {
        await processLogin();
      }
    }

    const processLogin = async () => {
      handleLogin()
        .then((response) => {
          handleUpdateUser(response)
        })
        .then(() => {
          handleRedirect()
        })
        .catch((error) => {
          switch (error.response.data.error) {
            case "Wrong password":
              setMessage("Wrong password");
              break;

            case "cannot found this customer":
              setMessage("The account for this email cannot be found");
              break;

            default:
              setMessage("here was an error while process login, please refresh this page and try again.");
          }
        });
    }

    const handleLogin = async () => {
      let body = {
        "username": email,
        "email": email,
        "password": password,
        "logintype": 'email',
        "social_network_id": "",
        "access_token": "",
        "action": "login",
      }

      if (status === 'authenticated') {
        body.logintype = data.provider;
        body.email = data.user.email;

        if (data.provider === 'facebook') {
          body.social_network_id = data.user.id;
          body.access_token = data.user.accessToken;
        } else if (data.provider === 'google') {
          body.social_network_id = data.user.id;
        }
      }

      const response = await axios.post("/api/auth", body);

      return response.data;
    }

    const handleUpdateUser = async (response: any) => {
      let user: User = {
        user_id: response.user_id,
        email: response.email,
        level: response.membership_level,
        token: response.token,
        barcode: '',
        mooimom_point: 0,
        name: userData.name !== null && userData.name.length > 0 ? userData.name : response.name,
        phone: '',
        referral_code: '',
        uuid: userData.uuid,
        refresh_token: response.refresh_token,
        country_code: '',
        birthday: null,
        gender: ''
      }

      updateUser(user);
      setUserData(user);
    }

    const handleRedirect = () => {
      if (redirect != undefined) {
        if (typeof(redirect) === 'string') {
          router.push(redirect);
        } else {
          router.push(redirect[0]);
        }
      } else {
        router.push('./');
      }
    }

    if (status === 'authenticated') {
      if (sbind != undefined) {

      }

      processLogin();
    }

    if (userData.level !== "none" && userData.email !== null && userData.level !== "" && userData.email !== "") {
      handleRedirect();
    }

    const loginBtn = document.getElementById("loginBtn");

    if (loginBtn !== null) {
      loginBtn.addEventListener("click", checkInput);

      return () => {
        loginBtn.removeEventListener('click', checkInput);
      };
    }
  }, [data, email, password, redirect, router, sbind, status, updateUser, userData.email, userData.level, userData.name, userData.uuid])

  useEffect(() => {
    if (message !== "") {
      setOpen(true);
    }
  }, [message]);

  useEffect(() => {
    if (!open) {
      setMessage("");
    }
  }, [open]);

  return (
    <div className="w-full grid gird-cols-1 md:grid-cols-2 gap-12 md:items-center mb-[69px] md:mb-0">
      <div className="hidden w-full md:block">
        <Image
          src={process.env.NEXT_PUBLIC_STATIC_SITE + "/images/login-desktop.jpg"}
          alt="login image"
          width={900}
          height={921}
          quality={100}
        />
      </div>
      <div className="w-full md:hidden">
        <Image
          src={process.env.NEXT_PUBLIC_STATIC_SITE + "/images/login-mobile.jpg"}
          alt="login image"
          width={600}
          height={400}
          quality={100}
        />
      </div>
      <div className="mx-[35px] md:mx-auto md:w-[400px] font-dmsans">
        <h1 className="text-[24px] md:text-[32px] leading-none font-bold text-primary text-center md:text-left w-full">Login</h1>
        <div className="flex mt-6 text-sm font-medium text-primary justify-center md:justify-start">
          <p className="mr-2">Don&apos;t have an account?</p>
          <Link href="/signup">
            <p className="underline">Sign up here</p>
          </Link>
        </div>
        <input
          type="text"
          name="email"
          value={email}
          placeholder="Email"
          className="w-full py-4 px-8 mt-6 text-sm placeholder:text-border border border-border"
          onChange={event => setEmail(event.target.value)}
        />
        <input
          type="password"
          name="password"
          value={password}
          placeholder="Password"
          className="w-full py-4 px-8 mt-6 text-sm placeholder:text-border border border-border"
          onChange={event => setPassword(event.target.value)}
        />
        <p className="text-sm font-medium text-primary mt-6 underline text-center md:text-left">
          <Link href="password">
            Forgot your password?
          </Link>
        </p>
        <button
          className="bg-buttonBg w-full text-white text-base font-medium py-4 px-12 text-center mt-6"
          id="loginBtn"
        >
          Login
        </button>
        {/* <div className="flex items-center justify-center w-full my-6">
          <p className="text-sm font-normal text-primary capitalize mx-4">
            or continue with
          </p>
          <hr className="border-t border-border border-solid"></hr>
        </div>
        <div className="flex justify-center">
          <button
            className=""
            onClick={() => signIn("google")}
          >
            <Image
              src={process.env.NEXT_PUBLIC_STATIC_SITE + "/images/google.png"}
              alt="google icon"
              quality={100}
              width={32}
              height={32}
              className=""
            />
          </button>
          <button
            className=""
            onClick={() => signIn("facebook")}
          >
            <Image
              src={process.env.NEXT_PUBLIC_STATIC_SITE + "/images/facebook.png"}
              alt="google icon"
              quality={100}
              width={32}
              height={32}
              className="ml-4"
            />
          </button>
        </div> */}
      </div>
      <AlertDialog message={message} open={open} setOpen={setOpen} />
    </div>
  )
}

export default Login
